import React, { useEffect } from "react";
import { SlideshowLightbox } from "lightbox.js-react";
// import { Link } from 'react-router-dom';
// import { slugify } from '../../../utils';
import "lightbox.js-react/dist/index.css";
import { initLightboxJS } from "lightbox.js-react";

const PropOne = ({ portfolio1103, projectStyle, portfolio }) => {
  useEffect(() => {
    initLightboxJS("Insert your License Key here", "Insert plan type here");
  }, []);

  return (
    <>
      {portfolio1103 ? (
        <div className={`project-grid ${projectStyle}`}>
          <div className="thumbnail">
            {/* <Link to={process.env.PUBLIC_URL + `/project-details/${slugify(portfolio.title)}`}> */}
            {/* <img src={process.env.PUBLIC_URL + portfolio.image} alt="icon" /> */}
            <SlideshowLightbox className="container ps-2 d-flex gap-2 mx-auto pe-0">
              {portfolio.image1.map((item, index) => (
                <img src={item} key={index} alt=""/>                
              ))}
              {/* <img src={portfolio.image} />
            <img src={portfolio.image1} />
            <img src={portfolio.image2} />
            <img src={portfolio.image3} /> */}
            </SlideshowLightbox>
            {/* <img src={portfolio.image} alt="portfolio" /> */}
            {/* <img src={portfolio.image} alt="portfolio" /> */}
            {/* <img src={portfolio.image} alt="portfolio" /> */}
            {/* <img src={portfolio.image} alt="portfolio" /> */}
            {/* <img src={portfolio.image} alt="portfolio" /> */}
            {/* <img src={portfolio.image} alt="portfolio" /> */}
            {/* <img src={portfolio.image} alt="portfolio" /> */}
            {/* <img src={portfolio.image} alt="portfolio" /> */}
            {/* </Link> */}
          </div>
          <div className="content">
            <h4 className="title">
              {portfolio.title}
              {/* <Link to={process.env.PUBLIC_URL + `/project-details/${slugify(portfolio.title)}`}>{portfolio.title}</Link> */}
            </h4>
            <span className="subtitle">
              {portfolio.category.map((cat, i) => (
                <span key={i}>{cat}</span>
              ))}
            </span>
          </div>
        </div>
      ) : (
        <div className={`project-grid ${projectStyle}`}>
        <div className="thumbnail">
          {/* <Link to={process.env.PUBLIC_URL + `/project-details/${slugify(portfolio.title)}`}> */}
          {/* <img src={process.env.PUBLIC_URL + portfolio.image} alt="icon" /> */}
          
          <img src={portfolio.img} alt="portfolio" />
          {/* </Link> */}
        </div>
        <div className="content">
          <h4 className="title">
            {portfolio.title}
            {/* <Link to={process.env.PUBLIC_URL + `/project-details/${slugify(portfolio.title)}`}>{portfolio.title}</Link> */}
          </h4>
          <span className="subtitle">
            {portfolio.category.map((cat, i) => (
              <span key={i}>{cat}</span>
            ))}
          </span>
        </div>
      </div>
      )}
    </>
  );
};

export default PropOne;
