import React, { useState, useEffect } from "react";
import ProjectPropOne from "./itemProp/ProjectPropOne";
import SectionTitle from "../../elements/section-title/SectionTitle";
// import ProjectData from "../../data/project/ProjectData.json";
import image1 from "../../assets/image/portfolio/primeship.png";
import image2 from "../../assets/image/portfolio/blackpledge.png";
import image3 from "../../assets/image/portfolio/hmis.png";
import image4 from "../../assets/image/portfolio/3d4kids.png";
import image11 from "../../assets/image/portfolio/tradeunlisted.png";
import image13 from "../../assets/image/portfolio/dskyadmmin.png";
import image14 from "../../assets/image/portfolio/univault.png";
import image15 from "../../assets/image/portfolio/whatismyip.png";
import tradeunlisted1 from "../../assets/image/portfolio/tradeunlisted1.png";
import primeship2 from "../../assets/image/portfolio/primeship2.png";
import primeship3 from "../../assets/image/portfolio/primeship3.png";
import shipmate from "../../assets/image/portfolio/Shipmate.png";
import shipmate1 from "../../assets/image/portfolio/Shipmate2.png";
import shipmate2 from "../../assets/image/portfolio/Shipmate3.png";
import shipmate3 from "../../assets/image/portfolio/Shipmate4.png";
import conciergeclub1 from "../../assets/image/portfolio/conciergeclub5.png";
import conciergeclub2 from "../../assets/image/portfolio/conciergeclub2.png";
import conciergeclub3 from "../../assets/image/portfolio/conciergeclub3.png";
import conciergeclub5 from "../../assets/image/portfolio/conciergeclub1.png";
import koski1 from "../../assets/image/portfolio/koski3.png";
import koski2 from "../../assets/image/portfolio/koski2.png";
import koski3 from "../../assets/image/portfolio/koski1.png";
import clothesmnia from "../../assets/image/portfolio/clothesmania1.png";
import clothesmnia1 from "../../assets/image/portfolio/clothesmania2.png";
import clothesmnia2 from "../../assets/image/portfolio/clothesmania3.png";
import Goodera1 from "../../assets/image/portfolio/goodera1.png";
import Goodera2 from "../../assets/image/portfolio/goodera2.png";
import Goodera3 from "../../assets/image/portfolio/goodera3.png";
import Goodera4 from "../../assets/image/portfolio/goodera4.png";
import blinds1 from "../../assets/image/portfolio/blinds1.png";
import blinds2 from "../../assets/image/portfolio/blinds2.png";
import blinds3 from "../../assets/image/portfolio/blinds3.png";
import blinds4 from "../../assets/image/portfolio/blinds4.png";
import diakraff1 from "../../assets/image/portfolio/diakraff1.png";
import diakraff2 from "../../assets/image/portfolio/diakraff2.png";
import diakraff3 from "../../assets/image/portfolio/diakraff3.png";
import diakraff4 from "../../assets/image/portfolio/diakraff4.png";
import inkppt1 from "../../assets/image/portfolio/inkppt1.png";
import inkppt2 from "../../assets/image/portfolio/inkppt2.png";
import inkppt3 from "../../assets/image/portfolio/inkppt3.png";
import inkppt4 from "../../assets/image/portfolio/inkppt4.png";
import upsqode1 from "../../assets/image/portfolio/upsqode3.png";
import upsqode2 from "../../assets/image/portfolio/upsqode1.png";
import upsqode3 from "../../assets/image/portfolio/upsqode2.png";
import upsqode4 from "../../assets/image/portfolio/upsqode4.png";
import Fearless1 from "../../assets/image/portfolio/Fearless1.png";
import Fearless2 from "../../assets/image/portfolio/Fearless2.png";
import Fearless3 from "../../assets/image/portfolio/Fearless3.png";
import Fearless4 from "../../assets/image/portfolio/Fearless4.png";
import dsky2 from "../../assets/image/portfolio/d-sky2.png";
import dsky3 from "../../assets/image/portfolio/d-sky3.png";
import dsky4 from "../../assets/image/portfolio/d-sky4.png";
import certificate1 from "../../assets/image/portfolio/My certificate.png";
import certificate2 from "../../assets/image/portfolio/Get started.png";
import certificate3 from "../../assets/image/portfolio/Certificate Maker.png";
import certificate4 from "../../assets/image/portfolio/Portrait.png";
import certificate5 from "../../assets/image/portfolio/Landscape.png";
import certificate6 from "../../assets/image/portfolio/My certificate.png";
import newreassiace1 from "../../assets/image/portfolio/newrenaissance1.png";
import newreassiace2 from "../../assets/image/portfolio/newrenaissance2.png";
import newreassiace3 from "../../assets/image/portfolio/newrenaissance3.png";
import newreassiace4 from "../../assets/image/portfolio/newrenaissance4.png";
import newreassiace5 from "../../assets/image/portfolio/newrenaissance5.png";
import vendor1 from "../../assets/image/portfolio/Login.png";
import vendor2 from "../../assets/image/portfolio/Dashboard.png";
import vendor3 from "../../assets/image/portfolio/Listing View _ List.png";
import vendor4 from "../../assets/image/portfolio/Listing View.png";
import vendor5 from "../../assets/image/portfolio/Add a listing.png";
import reseller1 from "../../assets/image/portfolio/reseller1.png";
import reseller2 from "../../assets/image/portfolio/reseller2.png";
import reseller3 from "../../assets/image/portfolio/reseller3.png";
import reseller4 from "../../assets/image/portfolio/reseller4.png";
import reseller5 from "../../assets/image/portfolio/reseller5.png";
import dskyretailer1 from "../../assets/image/portfolio/dskyretailer1.webp";
import dskyretailer2 from "../../assets/image/portfolio/dskyretailer2.webp";
import dskyretailer3 from "../../assets/image/portfolio/dskyretailer3.webp";
import dskyretailer4 from "../../assets/image/portfolio/dskyretailer4.webp";
import dskycashcollector1 from "../../assets/image/portfolio/dskycashcollector1.webp";
import dskycashcollector2 from "../../assets/image/portfolio/dskycashcollector2.webp";
import dskycashcollector3 from "../../assets/image/portfolio/dskycashcollector3.webp";
import dskycashcollector4 from "../../assets/image/portfolio/dskycashcollector4.webp";
import fracassets1 from "../../assets/image/portfolio/fracassets.png";
import fracassets2 from "../../assets/image/portfolio/fracassets1.png";
import fracassets3 from "../../assets/image/portfolio/fracassets2.png";
import fracassets4 from "../../assets/image/portfolio/fracassets3.png";
import fracassets5 from "../../assets/image/portfolio/fracassets4.png";
import fracassets6 from "../../assets/image/portfolio/fracassets5.png";
import tradeunlisted2 from "../../assets/image/portfolio/tradeunlisted2.png";
import tradeunlisted3 from "../../assets/image/portfolio/tradeunlisted3.png";
import tradeunlisted4 from "../../assets/image/portfolio/tradeunlisted4.png";
import univault1 from "../../assets/image/portfolio/univault1.png";
import univault2 from "../../assets/image/portfolio/univault2.png";
import univault3 from "../../assets/image/portfolio/univault3.png";
import univault4 from "../../assets/image/portfolio/univault4.png";
import whatismyip1 from "../../assets/image/portfolio/whatismyip.png";
import whatismyip2 from "../../assets/image/portfolio/whatismyip1.png";
import whatismyip3 from "../../assets/image/portfolio/whatismyip2.png";
import whatismyip4 from "../../assets/image/portfolio/whatismyip3.png";
import dskyhtml from "../../assets/image/portfolio/dskyhtml.png";
import dskyhtml1 from "../../assets/image/portfolio/dskyhtml1.png";
import dskyhtml2 from "../../assets/image/portfolio/dskyhtml2.png";
import dskyhtml3 from "../../assets/image/portfolio/dskyhtml3.png";
import hmis1 from "../../assets/image/portfolio/hmis1.png";
import hmis2 from "../../assets/image/portfolio/hmis2.png";
import hmis3 from "../../assets/image/portfolio/hmis3.png";
import hmis4 from "../../assets/image/portfolio/hmis4.png";
import kids1 from "../../assets/image/portfolio/kidsangular1.png";
import kids2 from "../../assets/image/portfolio/kidsangular2.png";
import kids3 from "../../assets/image/portfolio/kidsangular3.png";
import kids4 from "../../assets/image/portfolio/kidsangular4.png";

const ProjectData = [
  {
    id: 1,
    image1: [image1, primeship2, primeship3],
    img: image1,
    title: "Primeship",
    category: ["Angular"],
    excerpt:
      "The appeal of mobile apps for its market potential cannot fade away anytime soon, at least until another great technology can replace mobile apps.",
    body: [
      "Mobile apps have already penetrated every sphere of our lives and every imaginable business niche. Naturally, the appeal of mobile apps for its market potential cannot fade away anytime soon, at least until another great technology can replace mobile apps. development company and its business clients. . So, what about the failure of other apps? What are the key factors responsible for the failure of millions of apps?",
      "Front-end development essentially focuses and works on the visual perceptions of a website. It’s about the look, load time, pattern and functionality of the components of a website. Front-End is a mix of programming and layout that powers the visuals and interactions on the web. ",
    ],
  },
  {
    id: 2,
    image1: [image2, primeship2, primeship2],
    img: image2,
    title: "Black Pledge",
    category: ["Angular"],
    excerpt:
      "We build our computer (systems) the way we build our cities: over time, without a plan, on top of ruins.",
    body: [
      "The project is entirely client-based, as I must work with Angular & Datastore should be in Firebase For the media. This is the leading page is for the app how to use it & more information for users.",
    ],
  },
  {
    id: 3,
    image1: [image3, hmis1, hmis2, hmis3, hmis4],
    img: image3,
    title: "HMIS",
    category: ["Angular"],
    excerpt:
      "We build our computer (systems) the way we build our cities: over time, without a plan, on top of ruins.",
    body: [
      "The project is entirely client-based, as I must work with Angular & Datastore should be in MongoDB (NoSQL). It’s a Fully Angular base E-commerce site.",
    ],
  },
  {
    id: 4,
    image1: [image4, kids1, kids2, kids3, kids4],
    img: image4,
    title: "3d4 kids",
    category: ["Angular"],
    excerpt:
      "Front-end development essentially focuses and works on the visual perceptions of a website. It’s about the look, load time, pattern and functionality of the components of a website. Front-End is a mix of programming and layout that powers the visuals and interactions on the web.",
    body: [
      "Mobile apps have already penetrated every sphere of our lives and every imaginable business niche. Naturally, the appeal of mobile apps for its market potential cannot fade away anytime soon, at least until another great technology can replace mobile apps. development company and its business clients. . So, what about the failure of other apps? What are the key factors responsible for the failure of millions of apps?",
      "Front-end development essentially focuses and works on the visual perceptions of a website. It’s about the look, load time, pattern and functionality of the components of a website. Front-End is a mix of programming and layout that powers the visuals and interactions on the web.",
    ],
  },
  {
    id: 16,
    image1: [dskyhtml, dskyhtml1, dskyhtml2, dskyhtml3],
    img: dskyhtml,
    title: "D-SKY",
    category: ["HTML/CSS"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "This application helps everyone to arrange their party and invite as many friends as they want. It helps them to register even minor details related to the party. Moreover, the colour combination is also attractive. Additionally, various functionalities are given such as search bar, remember the party, my parties, participate party, capture party presence, rate party and many more.",
    ],
  },
  {
    id: 8,
    image1: [dskyretailer1, dskyretailer2, dskyretailer3, dskyretailer4],
    img: dskyretailer1,
    title: "D-sky Retailer",
    category: ["Ionic"],
    excerpt: "Ecommerce isn’t the cherry on the cake, it’s the new cake",
    body: [
      "Because Ionic is required for the app, the project is fully client-based. Both apps must be available, one on Google Play and the other on Apple’s App Store. For your convenience, I’ve put all of the links below the image.",
    ],
  },
  {
    id: 9,
    image1: [
      dskycashcollector1,
      dskycashcollector2,
      dskycashcollector3,
      dskycashcollector4,
    ],
    img: dskycashcollector1,
    title: "D-SKY Cash Collector",
    category: ["Ionic"],
    excerpt:
      "we will convert jpg to single page responsive site with all the device show user friendly design.",
    body: [
      "Mobile apps have already penetrated every sphere of our lives and every imaginable business niche. Naturally, the appeal of mobile apps for its market potential cannot fade away anytime soon, at least until another great technology can replace mobile apps.",
    ],
  },
  {
    id: 11,
    image1: [
      image11,
      tradeunlisted1,
      tradeunlisted2,
      tradeunlisted3,
      tradeunlisted4,
    ],
    img: image11,
    title: "TradeUnlisted",
    category: ["React"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "In this site we will make animated web Design to make Awesome look. this site is fully responsive site.",
    ],
  },
  {
    id: 12,
    image1: [
      fracassets1,
      fracassets2,
      fracassets3,
      fracassets4,
      fracassets5,
      fracassets6,
    ],
    img: fracassets1,
    title: "FracaAssets",
    category: ["React"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "This application helps everyone to arrange their party and invite as many friends as they want. It helps them to register even minor details related to the party. Moreover, the colour combination is also attractive. Additionally, various functionalities are given such as search bar, remember the party, my parties, participate party, capture party presence, rate party and many more.",
    ],
  },
  {
    id: 13,
    image1: [image13, dsky2, dsky3, dsky4],
    img: image13,
    title: "Dskylink",
    category: ["React"],
    excerpt:
      "In this site we will make animated web Design to make Awesome look.",
    body: [
      "this project is easy to understand project structure and scalable , fast Loaded.",
    ],
  },
  {
    id: 14,
    image1: [image14, univault1, univault2, univault3, univault4],
    img: image14,
    title: "Univault",
    category: ["React"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
    ],
  },
  {
    id: 15,
    image1: [image15, whatismyip1, whatismyip2, whatismyip3, whatismyip4],
    img: image15,
    title: "Whatismyip",
    category: ["React"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
    ],
  },
  {
    id: 16,
    image1: [shipmate, shipmate1, shipmate2, shipmate3],
    img: shipmate,
    title: "Shipmate",
    category: ["UI/UX"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "This application helps everyone to arrange their party and invite as many friends as they want. It helps them to register even minor details related to the party. Moreover, the colour combination is also attractive. Additionally, various functionalities are given such as search bar, remember the party, my parties, participate party, capture party presence, rate party and many more.",
    ],
  },
  {
    id: 27,
    image1: [dsky2, dsky3, dsky4],
    img: dsky2,
    title: "D-sky",
    category: ["UI/UX"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "This application helps everyone to arrange their party and invite as many friends as they want. It helps them to register even minor details related to the party. Moreover, the colour combination is also attractive. Additionally, various functionalities are given such as search bar, remember the party, my parties, participate party, capture party presence, rate party and many more.",
    ],
  },
  {
    id: 28,
    image1: [
      certificate1,
      certificate2,
      certificate3,
      certificate4,
      certificate5,
      certificate6,
    ],
    img: certificate1,
    title: "Certificate",
    category: ["UI/UX"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "This application helps everyone to arrange their party and invite as many friends as they want. It helps them to register even minor details related to the party. Moreover, the colour combination is also attractive. Additionally, various functionalities are given such as search bar, remember the party, my parties, participate party, capture party presence, rate party and many more.",
    ],
  },
  {
    id: 29,
    image1: [
      newreassiace1,
      newreassiace2,
      newreassiace3,
      newreassiace4,
      newreassiace5,
    ],
    img: newreassiace1,
    title: "Certificate",
    category: ["UI/UX"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "This application helps everyone to arrange their party and invite as many friends as they want. It helps them to register even minor details related to the party. Moreover, the colour combination is also attractive. Additionally, various functionalities are given such as search bar, remember the party, my parties, participate party, capture party presence, rate party and many more.",
    ],
  },
  {
    id: 30,
    image1: [vendor1, vendor2, vendor3, vendor4, vendor5],
    img: vendor1,
    title: "Vendor",
    category: ["UI/UX"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "This application helps everyone to arrange their party and invite as many friends as they want. It helps them to register even minor details related to the party. Moreover, the colour combination is also attractive. Additionally, various functionalities are given such as search bar, remember the party, my parties, participate party, capture party presence, rate party and many more.",
    ],
  },
  {
    id: 31,
    image1: [reseller1, reseller2, reseller3, reseller4, reseller5],
    img: reseller1,
    title: "Reseller",
    category: ["UI/UX"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "This application helps everyone to arrange their party and invite as many friends as they want. It helps them to register even minor details related to the party. Moreover, the colour combination is also attractive. Additionally, various functionalities are given such as search bar, remember the party, my parties, participate party, capture party presence, rate party and many more.",
    ],
  },
  {
    id: 32,
    image1: [
      fracassets2,
      fracassets1,
      fracassets3,
      fracassets4,
      fracassets5,
      fracassets6,
    ],
    img: fracassets2,
    title: "FracaAssets",
    category: ["UI/UX"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "This application helps everyone to arrange their party and invite as many friends as they want. It helps them to register even minor details related to the party. Moreover, the colour combination is also attractive. Additionally, various functionalities are given such as search bar, remember the party, my parties, participate party, capture party presence, rate party and many more.",
    ],
  },
  {
    id: 17,
    image1: [conciergeclub1, conciergeclub2, conciergeclub3, conciergeclub5],
    img: conciergeclub1,
    title: "CONCIERGECLUB",
    category: ["WordPress"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
    ],
  },
  {
    id: 18,
    image1: [koski1, koski2, koski3],
    img: koski1,
    title: "KOSKII",
    category: ["WordPress"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
    ],
  },
  {
    id: 19,
    image1: [clothesmnia1, clothesmnia, clothesmnia2],
    img: clothesmnia1,
    title: "CLOTHESMANIA",
    category: ["WordPress"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
    ],
  },
  {
    id: 20,
    image1: [Goodera2, Goodera1, Goodera3, Goodera4],
    img: Goodera2,
    title: "Goodera",
    category: ["WordPress"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
    ],
  },
  {
    id: 21,
    image1: [blinds2, blinds1, blinds3, blinds4],
    img: blinds1,
    title: "Blinds",
    category: ["WordPress"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
    ],
  },
  {
    id: 22,
    image1: [diakraff2, diakraff1, diakraff3, diakraff4],
    img: diakraff2,
    title: "Diakraff",
    category: ["WordPress"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
    ],
  },
  {
    id: 24,
    image1: [inkppt2, inkppt1, inkppt3, inkppt4],
    IMG: inkppt2,
    title: "Inkppt",
    category: ["WordPress"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
    ],
  },
  {
    id: 25,
    image1: [upsqode2, upsqode1, upsqode3, upsqode4],
    img: upsqode2,
    title: "Upsqode",
    category: ["WordPress"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
    ],
  },
  {
    id: 26,
    image1: [Fearless1, Fearless2, Fearless3, Fearless4],
    img: Fearless1,
    title: "Fearless",
    category: ["WordPress"],
    excerpt:
      "this project is easy to understand project structure and scalable , fast Loaded.",
    body: [
      "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
    ],
  },
];

const filters = [
  {
    id: 1,
    label: "All",
  },
  {
    id: 2,
    label: "Angular",
  },
  {
    id: 3,
    label: "HTML/CSS",
  },
  {
    id: 4,
    label: "Ionic",
  },
  {
    id: 5,
    label: "React",
  },
  {
    id: 6,
    label: "UI/UX",
  },
  {
    id: 7,
    label: "WordPress",
  },
];

const AllData = [...ProjectData];

const ProjectOne = ({
  portfolio1103,
  parentClass,
  colSize,
  itemShow,
  columnGap,
}) => {
  const [getAllItems] = useState(AllData);
  const [visiableProject] = useState(itemShow ? itemShow : 6);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    setActiveFilter(filters[0].label);
    setVisibleItems(getAllItems.filter((item) => item.id <= visiableProject));
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    let target = e.target.textContent;

    setActiveFilter(target);

    let tempData = [];
    if (target === filters[0].label) {
      tempData = getAllItems.filter((data) => data.id <= visiableProject);
    } else {
      for (let i = 0; i < getAllItems.length; i++) {
        const element = getAllItems[i];
        let categories = element["category"];

        if (categories.includes(target)) {
          tempData.push(element);
        }
      }
    }
    setVisibleItems(tempData);
  };

  return (
    <>
      <div
        className={`section section-padding-2 ${
          parentClass ? parentClass : ""
        }`}
      >
        <div className="container">
          <SectionTitle
            subtitle="Our Project"
            title="Few of our <br>
                        finest works."
            description="We believe in delivering quality-oriented breakthrough products and services aiming towards transforming the digital world."
            textAlignment="heading-left mb--40"
            textColor=""
          />
          <div className="isotope-button isotope-project-btn">
            {filters.map((filter) => (
              <button
                onClick={handleChange}
                className={filter.label === activeFilter ? "is-checked" : " "}
                key={filter.id}
              >
                {filter.label}
              </button>
            ))}
          </div>
          <div className={`row ${columnGap ? columnGap : "row-35"}`}>
            {visibleItems.map((data) => (
              <div className={colSize ? colSize : "col-md-6"} key={data.id}>
                <ProjectPropOne
                  projectStyle=""
                  portfolio1103={portfolio1103}
                  portfolio={data}
                />
              </div>
            ))}
          </div>
        </div>
        <ul className="shape-group-7 list-unstyled">
          <li className="shape shape-1">
            <img
              src={process.env.PUBLIC_URL + "/images/others/circle-2.png"}
              alt="circle"
            />
          </li>
          <li className="shape shape-2">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
              alt="Line"
            />
          </li>
          <li className="shape shape-3">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
              alt="Line"
            />
          </li>
        </ul>
      </div>
    </>
  );
};

export default ProjectOne;
